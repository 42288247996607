import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'
import JSZip from "jszip";

export default class extends Controller {
  static outlets = ['chart-state']
  connect() {
    this.chart = this.chartStateOutlet.getChart(this.chartIdValue)
  }

  async download() {
    const zip = new JSZip();
    const chartImages = zip.folder("chart-images");
    this.chartStateOutlet.getCharts().forEach(chart => {
      const chartDataUrl = chart.toBase64Image()
      const idx = chartDataUrl.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
      const content = chartDataUrl.substring(idx);
      chartImages.file(`${chart.options.plugins.title.text}.png`, content, { base64: true });
    })
    const zipData = URL.createObjectURL(await zip.generateAsync({ type: "blob" }))
    this.downloadFile(zipData)
    // if (this.chart) {
    //   const response = await post(this.downloadUrlValue, { body: JSON.stringify({ data: this.chart.toBase64Image()}) })
    //   if (response.ok) {
    //     // const link = document.createElement("a");
    //     // link.download =  "chart.png";
    //     // link.href = response.body
    //     // link.click();
    //     const text = await response.text
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = text
    //     // the filename you want
    //     a.download = 'todo-1.png';
    //     document.body.appendChild(a);
    //     a.click();

    //     // Do whatever do you want with the response body
    //     // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
    //   }
    // }
  }

  downloadFile(downloadUrl) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl
    // the filename you want
    a.download = `chart-images.zip`;
    document.body.appendChild(a);
    a.click();
  }
}